<template>
    <div class="content">
        <div>
            <div>
                <span style="font-size: 14px;">{{ $t('LAN.wechat_native_1') }}
                    <span id="price" style="color: #0ac265; font-weight: 400; font-size: 24px;">
                        {{ priceStr }}
                    </span>
                </span>
            </div>
            <qrcode-vue :value="payUrl" :size="size" level="H" style="margin-top: 10px;" />
            <div>
                <div style="
                  line-height: 30px;
                  background-color: #00c800;
                  font-size: 14px;
                  color: #fff;
                  text-align: center;
                  max-width: 270px;
                  margin-top: 10px;">{{ $t('LAN.wechat_native_2') }}</div>
            </div>
            <a style="cursor:pointer" @click="goTo('payReturnLink')">
                <div style="
                    width: 237px;
                    background-color: #0092ee;
                    font-size: 20px;
                    color: #fff;
                    padding: 10px;
                    text-align: center;
                    margin-top: 10px; 
                    text-decoration: none;
                    display: inline-block; 
                    border-radius: 5px;">
                    {{ $t('LAN.wechat_native_3') }}
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { getStore } from '@/utils/store'
import { useRoute, useRouter } from 'vue-router'
import { CHANNEL } from '@/configs/global'
import QrcodeVue from 'qrcode.vue'

export default {
    setup() {
        const { proxy } = getCurrentInstance();
        const route = useRoute()
        const router = useRouter()

        const state = reactive({
            payUrl: "",
            size: 256,
            priceStr: ""
        });

        onMounted(() => {
            proxy.$I(proxy,getStore({ name: 'lang' }))

            state.payUrl = route.query.wechat_url;
            state.priceStr = route.query.amount_str;
            try {
                var channel = route.query.channel || 1;
                var u = navigator.userAgent;
                var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                if (parseInt(channel) == CHANNEL.H5 || parseInt(channel) == CHANNEL.ZY_IOS || parseInt(channel) == CHANNEL.COPY_IOS || parseInt(channel) == CHANNEL.COPY_IOS2) {
                    // window.top.postMessage({
                    //     msg: "hzpayOrderNo",
                    //     outno: route.query.out_trade_no
                    // }, "*");
                } else {
                    if (isAndroid || isiOS) {
                        // window.prompt("zfOrderNo", route.query.out_trade_no);
                    }
                }
            } catch (error) {
                console.log(error)
            }
        });

        const goTo = (name, params) => {
            router.push({ name: name, query: params })
        }
        return {
            ...toRefs(state),
            goTo
        }
    },
    components: {
        QrcodeVue
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}
</style>
